import { Card } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

const breakpoints = {
  mobile: 375, // 375px
  xsMax: tokens.layout.xsMax, // 575px
  smMin: tokens.layout.smMin, // 576px
  mdMin: tokens.layout.mdMin, // 768px
  mdMax: tokens.layout.mdMax, // 991px
  lgMin: tokens.layout.lgMax, // 1199px
  xlMin: tokens.layout.xlMin // 1200px
};

export const MainTheme = {
  breakpoints,
  mediaQueries: {
    mobile: `(max-width: 375px)`,
    maxWidthMobile: `(max-width: ${breakpoints.xsMax})`,
    small: `(min-width: ${breakpoints.xsMax}) and (max-width: ${breakpoints.mdMin})`,
    medium: `(min-width: ${breakpoints.mdMin}) and (max-width: ${breakpoints.lgMin})`,
    large: `min-width: ${breakpoints.lgMin}`
  }
};
export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`;

export const SubscriptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Section = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${tokens.layout.size4};
  max-width: 1140px;
  gap: ${tokens.layout.size2};

  && {
    border-radius: ${tokens.layout.size4};
  }
`;

export const SectionCard = styled(Section)`
  gap: ${tokens.layout.size4};
  font-size: ${tokens.typography.size1};
`;

export const SomethingElseSection = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${tokens.layout.size4};
  max-width: 1140px;
  gap: ${tokens.layout.size8};
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;

  p,
  a {
    font-size: ${tokens.typography.size1};
  }

  @media screen and (max-width: ${tokens.layout.xsMax}) {
    display: flex;
    flex-direction: column;
    gap: ${tokens.layout.size2};
  }
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${tokens.color.gray6};
  font-size: ${tokens.typography.size1};
`;

export const Link = styled.a`
  width: max-content;
  display: flex;
  align-items: center;
  color: ${tokens.color.hpBlue6};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const RowIcon = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.layout.size2};
  padding: 6px 12px 6px 0px;

  & > span {
    width: 100%;
  }
`;
