import { useQuery } from '@clientos/graphql-client/dist/react';
import {
  ApiSubscriptionStates,
  Card,
  CommercialSubscriptionStates,
  getSubscriptionCommercialState,
  Loading,
  SubscriptionType
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useMemo } from 'react';
import ErrorBoundary from 'src/App/ErrorBoundary';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import GET_SUBSCRIPTIONS from 'src/graphql/getSubscriptions';
import {
  Label,
  Link,
  Row,
  Section,
  SubscriptionsContainer
} from 'src/styles/styles';
import { Device } from 'src/types/IDevices';
import { Entity } from 'src/types/IEntities';
import {
  DeviceDetailsError,
  ManageDeviceSubscription,
  publishEvent
} from 'src/utils/analytics';
import { OptionsContent } from './styles';

interface SubscriptionsProps {
  device: Device;
  deviceDetails: string;
}

interface Subscription {
  resourceId: string;
  type: SubscriptionType;
  state: ApiSubscriptionStates;
  devices: { items: Device[] };
  entities: Entity[];
}

const Subscriptions = ({ device, deviceDetails }: SubscriptionsProps) => {
  const { translate } = useDependencyManagerContext();
  const { loading, error, data } = useQuery(GET_SUBSCRIPTIONS);

  // Finds all subscriptions where the subscription type is either 'INSTANT_INK' or 'HP_ALL_IN' and it has an entity of type 'INSTANT_INK'.
  // Checks if the subscriptions are associated with the specific device or entity (in the 'HP_ALL_IN' case).
  // Only returns subscriptions that are not inactive.
  const subscriptions = useMemo(() => {
    return data?.account?.subscriptions?.filter((sub: Subscription) => {
      const { type, state, devices, entities } = sub;

      const isInstantInkOrAllIn =
        type === SubscriptionType.INSTANT_INK ||
        type === SubscriptionType.HP_ALL_IN;
      const isActive =
        getSubscriptionCommercialState(state, type) !==
        CommercialSubscriptionStates.INACTIVE;
      const hasInstantInkEntity = entities?.some(
        (entity) => entity?.type === 'INSTANT_INK'
      );
      const hasMatchingDevice = devices?.items?.some(
        (subDevice) => subDevice?.deviceId === device?.deviceId
      );
      const hasMatchingEntity = entities?.some(
        (entity) =>
          entity?.__typename === 'DeviceEntity' &&
          entity?.product?.sku?.split('#')[0] ===
            device?.identity?.makeAndModel?.number
      );

      return (
        isInstantInkOrAllIn &&
        isActive &&
        hasInstantInkEntity &&
        (hasMatchingDevice || hasMatchingEntity)
      );
    });
  }, [
    data?.account?.subscriptions,
    device?.deviceId,
    device?.identity?.makeAndModel?.number
  ]);

  const instantInk = useMemo(() => {
    return subscriptions?.map((subscription: Subscription) => {
      const { entities, type } = subscription;

      const instantInkEntity = entities?.find(
        (entity: Entity) => entity?.type === 'INSTANT_INK'
      );
      const instantInkPaperEntity = entities?.find(
        (entity: Entity) => entity?.type === 'INSTANTPAPER'
      );

      const label = () => {
        return type === SubscriptionType.INSTANT_INK
          ? translate('common.instantInk', 'HP Instant Ink')
          : translate('common.allIn', 'HP All-In Plan');
      };

      const planDescription = () => {
        const plusPaper = instantInkPaperEntity
          ? ` ${translate('common.plusPaper', '+ Paper')}`
          : '';

        return instantInkEntity?.product?.description
          ? `${instantInkEntity.product.description} ${translate(
              'common.pages',
              'Pages'
            )}${plusPaper}`
          : '';
      };

      return (
        <Row key={subscription.resourceId}>
          <Label>{label()}</Label>
          <SubscriptionsContainer>
            {planDescription()}
            <Link
              className="caption"
              href={`/subscriptions/${subscription.resourceId}?type=${type}`}
              onClick={() => {
                publishEvent(ManageDeviceSubscription(deviceDetails));
              }}
            >
              {`${translate('devices.manage', 'Manage')}`}
            </Link>
          </SubscriptionsContainer>
        </Row>
      );
    });
  }, [deviceDetails, subscriptions, translate]);

  if (loading) {
    return (
      <Section
        appearance="outlined"
        content={<Loading />}
      />
    );
  }

  if (error) {
    return (
      <Section
        data-testid="subscriptions"
        appearance="outlined"
        content={
          <ErrorBoundary
            analyticsEvent={DeviceDetailsError(error?.message)}
            showOutline={false}
          />
        }
      />
    );
  }

  if (!subscriptions || subscriptions.length <= 0) {
    return null;
  }

  return (
    <Card
      data-testid="subscriptions"
      title={{
        content: translate(
          'devices.subscriptionsTitle',
          'Subscriptions'
        ) as string
      }}
    >
      <OptionsContent>
        {instantInk}
        {
          // TODO: add care pack
        }
      </OptionsContent>
    </Card>
  );
};

export default Subscriptions;
