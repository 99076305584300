import { Platform, Stack, createGraphQLClient } from '@clientos/graphql-client';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DeviceDetailing from 'src/pages/DeviceDetailing';
import Devices from 'src/pages/Devices';
import { Container } from './styles';
import '../styles/global.scss';
import * as T from './types';
import { createGraphQLProvider } from '@clientos/graphql-client/dist/react';
import { getGraphQLStack } from 'src/utils/getStack';
import { DependencyManagerProvider } from 'src/contexts/dependencyManager';

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = (props) => {
  const stack: Stack = getGraphQLStack();

  const client = createGraphQLClient({
    authTokenCallback: async () => {
      return await (props.authProvider as any).getAccessToken();
    },
    platform: Platform.WEB,
    stack,
    isShellPortal: true
  });

  const GraphQLProvider = createGraphQLProvider(React);

  return (
    <DependencyManagerProvider>
      <GraphQLProvider client={client}>
        <Container data-testid="devices-page">
          <BrowserRouter>
            <Routes>
              <Route
                path="/:country/:language/devices"
                element={<Devices />}
              />
              <Route
                path="/:country/:language/devices/:deviceId"
                element={<DeviceDetailing />}
              />
              <Route
                path="*"
                element={<Devices />}
              />
            </Routes>
          </BrowserRouter>
        </Container>
      </GraphQLProvider>
    </DependencyManagerProvider>
  );
};

export default App;
