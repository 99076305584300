import { PublishCdmEventsOptions } from '@jarvis/shell-commons/dist/interface/v1/analytics/types';
import {
  URLS,
  getHPSmartManageDeviceUsersAndSharingUrl,
  getHPSmartPrinterOverviewUrl
} from './urls';

const activity = 'UnifiedAcctMgmt-v01';
export const screenPath = '/ReactMyAccountDevices/';
export const screenPathDeviceDetails = '/ReactMyAccountDevices/Devices/';
const eventDetailVersion = '1.5.0';
export const enum ScreenName {
  DEVICES = 'Devices',
  DEVICE_DETAILS = 'DeviceDetails'
}

export interface AnalyticsEventPropTypes {
  actionAuxParams?: string;
  action: string;
  activity: string;
  screenPath: string;
  screenName: string;
  screenMode?: string;
  controlDetail?: string;
  version: string;
}

export interface AnalyticsEventWithControlName extends AnalyticsEventPropTypes {
  controlName: string;
}

export const publishEvent = (
  event: AnalyticsEventPropTypes,
  options?: PublishCdmEventsOptions
) => {
  const publishCdmEvents = (window as any)?.Shell?.v1?.analytics
    ?.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const dateTime = new Date().toISOString();
  const cdmEvents = {
    dateTime,
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: event,
    version: '1.5.0'
  };

  publishCdmEvents([cdmEvents], options);
};

const ACTIONS = {
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  CONTROL_TILE_CLICKED: 'ControlTileClicked',
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPER_LINK_CLICKED: 'ControlHyperLinkClicked',
  MODULE_DISPLAYED: 'ModuleDisplayed'
};

export const DevicesScreenDisplayed = (
  totalDeviceCT: string
): AnalyticsEventPropTypes => {
  return {
    action: ACTIONS.SCREEN_DISPLAYED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICES,
    actionAuxParams: totalDeviceCT,
    version: eventDetailVersion
  };
};

export const RefreshDevicesButtonClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: ScreenName.DEVICES,
  controlName: 'RefreshDevicesButton',
  version: eventDetailVersion
};

export const ControlTileClicked = (
  deviceDetails: string
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_TILE_CLICKED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICES,
    controlName: 'DeviceDetailsTileCard',
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const SeeDevicesPurchasedButtonClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: ScreenName.DEVICES,
  controlName: 'SeeDevicesPurchasedButton',
  controlDetail: URLS.DevicesPurchased.slice(0, 256),
  version: eventDetailVersion
};

export const GetSupportForDeviceNotShownButtonClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICES,
    controlName: 'GetSupportForDeviceNotShownButton',
    controlDetail: URLS.DeviceSupportNotShown,
    version: eventDetailVersion
  };

export const HPSmartDevicesMyPrintersButtonClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICES,
    controlName: 'HPSmartDevicesMyPrintersButton',
    controlDetail: getHPSmartPrinterOverviewUrl(),
    version: eventDetailVersion
  };

export const DeviceRegisterButtonClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: ScreenName.DEVICES,
  controlName: 'DeviceRegisterButton',
  controlDetail: URLS.Register,
  version: eventDetailVersion
};

export const DevicesError = (errorInfo: string): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: `${screenPath}Devices/`,
    screenName: 'DevicesError',
    version: eventDetailVersion
  };
};

// DEVICE DETAILS PAGE

export const DeviceDetailsError = (
  errorInfo: string
): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo?.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: `${screenPath}DeviceDetails/`,
    screenName: 'DeviceDetailsError',
    version: eventDetailVersion
  };
};

export const PrinterOverview = (
  deviceDetails: string
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_HYPER_LINK_CLICKED,
    activity,
    screenPath: screenPathDeviceDetails,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'PrinterOverviewLink',
    controlDetail: getHPSmartPrinterOverviewUrl(),
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const DeviceOverview = (
  deviceDetails: string
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_HYPER_LINK_CLICKED,
    activity,
    screenPath: screenPathDeviceDetails,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'PC',
    controlName: 'DeviceOverviewLink',
    controlDetail: URLS.SupportDashboard,
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const ViewWarrantyStatus = (
  deviceDetails: string
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_HYPER_LINK_CLICKED,
    activity,
    screenPath: screenPathDeviceDetails,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'ViewWarrantyStatusLink',
    controlDetail: URLS.SupportDashboard,
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const ManageDeviceSubscription = (
  deviceDetails: string
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_HYPER_LINK_CLICKED,
    activity,
    screenPath: screenPathDeviceDetails,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'ManageDeviceSubscriptionLink',
    controlDetail: URLS.ManageInstantInk,
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const ManageDeviceUsersAndSharing = (
  deviceDetails: string
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath: screenPathDeviceDetails,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'ManageDeviceUsersAndSharingButton',
    controlDetail: getHPSmartManageDeviceUsersAndSharingUrl(),
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const GetDeviceSupport = (
  deviceDetails: string
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath: screenPathDeviceDetails,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'GetDeviceSupportButton',
    controlDetail: URLS.DeviceSupport,
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};
